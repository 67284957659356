<template>
  <m-widget-container v-if="floors.length">
    <v-row
      align="center"
      :class="horizontalLayout ? 'floor-plan--horizontal' : 'floor-plan--vertical'"
    >
      <v-col :cols="!horizontalLayout ? '12' : '7'">
        <v-card-text class="floor-plan-container">
          <resize-observer @notify="redrawItems" />
          <div
            class="floor-plan-base"
            :style="`--room-active-color: ${getColor($store.getters.layoutTheme || $vuetify.theme.themes.light, roomActiveColor)}`"
          >
            <v-img
              ref="image"
              :src="currentFloor.image"
              @load="redrawItems"
            >
              <svg
                class="floor-plan-plan"
                width="100%"
                height="100%"
              >
                <path
                  v-for="item in items"
                  :key="item.index"
                  :class="`room ${clickedRowRoomPath === item.originalPath ? 'active' : '' }`"
                  :d="item.d"
                  @click="roomClicked(item)"
                />
              </svg>
            </v-img>
          </div>
          <v-tabs
            v-if="floors.length > 1"
            v-model="activeTab"
            fixed-tabs
            :dark="dark"
            background-color="transparent"
            color="white"
          >
            <v-tab
              v-for="floor in floors"
              :key="floor.id"
              @click="selectedFloorId = floor.id"
            >
              {{ floor.title }}
            </v-tab>
          </v-tabs>
        </v-card-text>
      </v-col>
      <v-col :cols="!horizontalLayout ? '12' : '5'">
        <v-card-text v-m-click-outside="hideKeyboard" class="floor-plan-details">
          <v-text-field
            ref="searchField"
            v-model="search"
            :label="searchLabel"
            hide-hint
            :dark="dark"
            outlined
            hide-details
            :append-icon="search ? 'mdi-close' : ''"
            @click:append.prevent="clearSearch"
            @focus="showKeyboard"
          />
          <v-card
            v-show="keyboard.visible"
            class="mx-4 floor-plan-keyboard"
            :ripple="false"
            @click.stop.prevent="$refs.searchField.focus()"
          >
            <v-card-title>
              <v-spacer />
              <v-btn
                fab
                text
                @click.stop.prevent="keyboard.visible = false"
              >
                <v-icon color="white" v-text="'mdi-close'" />
              </v-btn>
            </v-card-title>
            <v-card-text class="pa-3">
              <vue-touch-keyboard
                v-if="keyboard.visible"
                :options="keyboard.options"
                :layout="keyboard.layout"
                :input="keyboard.input"
                :cancel="hideKeyboard"
                :accept="acceptKeyboard"
              />
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="rooms"
            :items-per-page="10"
            :search="search"
            :light="!dark"
            :dark="dark"
            class="background-table v-data-table--no-row-select"
            :footer-props="footerProps"
            :sort-by="['title']"
            must-sort
            @click:row="rowClicked"
          >
            <template #[`item.floorId`]="{ item }">
              {{ (floors.find(x => x.id === item.floorId) || {}).title }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-col>
    </v-row>
    <v-dialog
      v-model="companyDialog"
      max-width="35em"
      scrollable
    >
      <v-card v-if="clickedRoom">
        <v-toolbar flat>
          <v-toolbar-title>
            {{ clickedRoom.room }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="companyDialog = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text v-if="clickedRoom.logo">
          <v-img :src="clickedRoom.logo" />
        </v-card-text>
        <v-card-title style="word-break: break-word;">
          {{ clickedRoom.title }}
        </v-card-title>
        <v-card-text style="white-space: pre-line; word-break: break-word;">
          {{ clickedRoom.information }}
        </v-card-text>
        <v-card-text class="d-flex justify-end pt-3 pb-10">
          <div ref="qart" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </m-widget-container>
</template>

<script>
import QArt from 'qartjs'
import VueTouchKeyboard from 'vue-touch-keyboard'
import 'vue-touch-keyboard/dist/vue-touch-keyboard.css' // load default style
import { mWidgetMixin } from '../../../mixins'

export default {
  name: 'MFloorPlan',
  components: {
    'vue-touch-keyboard': VueTouchKeyboard.component
  },
  mixins: [mWidgetMixin],
  props: {
    floors: {
      type: Array,
      default: () => []
    },
    rooms: {
      type: Array,
      default: () => []
    },
    roomActiveColor: {
      type: String,
      default: () => ''
    },
    defaultFloorId: {
      type: String,
      default: () => ''
    },
    horizontalLayout: {
      type: Boolean,
      default: false
    },
    tabColor: {
      type: String,
      default: () => 'primary'
    },
    dark: {
      type: Boolean,
      default: () => true
    },
    searchLabel: {
      type: String,
      default: () => 'Search'
    }
  },
  data() {
    return {
      items: [],
      currentFloor: undefined,
      activeTab: 0,
      pointTypes: {
        M: 'moveto',
        L: 'lineto',
        H: 'horizontal lineto',
        V: 'vertical lineto',
        C: 'curveto',
        S: 'smooth curveto',
        Q: 'quadratic Bézier curve',
        T: 'smooth quadratic Bézier curveto',
        A: 'elliptical Arc',
        Z: 'closepath'
      },
      headers: [
        {
          text: 'Title',
          align: 'left',
          sortable: true,
          value: 'title'
        },
        {
          text: 'Floor',
          align: 'left',
          sortable: true,
          value: 'floorId'
        },
        {
          text: 'Room',
          align: 'left',
          sortable: true,
          value: 'room'
        }
      ],
      footerProps: {
        'disable-items-per-page': true
      },
      selectedFloorId: undefined,
      clickedRoom: undefined,
      clickedRowRoomPath: undefined,
      companyDialog: false,
      search: '',
      keyboard: {
        visible: false,
        layout: 'normal',
        input: null,
        options: {
          useKbEvents: false,
          preventClickEvent: true
        }
      }
    }
  },
  watch: {
    rooms() {
      this.redrawItems()
    },
    selectedFloorId: {
      handler(floorId) {
        this.currentFloor = this.floors.find(x => x.id === floorId || this.defaultFloorId) || this.floors[0]
      },
      immediate: true
    },
    currentFloor: {
      handler(floor) {
        this.activeTab = this.floors.indexOf(floor)
      },
      immediate: true
    },
    companyDialog(val) {
      if (!val) {
        this.clickedRoom = undefined
        return
      }
      const room = this.clickedRoom
      if (!room || !room.qrCode) {
        return
      }
      this.$nextTick(() => {
        setTimeout(() => {
          const { offsetWidth } = this.$refs.qart.parentNode
          const qart = new QArt({
            value: room.qrCode,
            imagePath: '',
            filter: 'color',
            background: '#fdd835',
            size: parseInt(offsetWidth / 3),
            version: 10
          })
          qart.make(this.$refs.qart)
        })
      })
    }
  },
  methods: {
    redrawItems() {
      this.items = this.rooms.filter(x => x.floorId === this.currentFloor.id).map(({ d }, index) => {
        return {
          index,
          originalPath: d,
          d: this.scalePoints(d)
        }
      })
    },
    scalePoints(d, reverse) {
      const { offsetWidth, offsetHeight } = this.$refs.image.$el
      return d.split(' ').map((coord) => {
        if (coord === 'Z') {
          return coord
        }
        let [x, y] = coord.split(',')
        let letter = x[0].toUpperCase()
        letter = Object.keys(this.pointTypes).includes(letter) ? letter : ''
        x = letter !== '' ? x.substring(1) : x
        if (!reverse) {
          x = x * offsetWidth / 100
          y = y * offsetHeight / 100
        } else {
          x = x * 100 / offsetWidth
          y = y * 100 / offsetHeight
        }
        return `${letter}${Math.round(x, 2)},${Math.round(y, 2)}`
      }).join(' ')
    },
    roomClicked(item) {
      this.clickedRowRoomPath = item.originalPath
      this.clickedRoom = this.rooms.filter(x => x.floorId === this.currentFloor.id).find(x => x.d === item.originalPath)
      if (!this.clickedRoom) {
        alert('Room could not be found')
        return
      }
      if (this.clickedRoom.pageId) {
        this.$emit('open-page', this.selectedCompany.pageId)
        return
      }
      this.companyDialog = true
    },
    rowClicked({ floorId, d }) {
      this.selectedFloorId = floorId
      this.clickedRowRoomPath = d
    },
    acceptKeyboard(text) {
      this.hideKeyboard()
    },
    showKeyboard(e) {
      this.keyboard.input = e.target
      if (!this.keyboard.visible) {
        this.keyboard.visible = true
      }
    },
    hideKeyboard($event) {
      this.keyboard.visible = false
    },
    clearSearch() {
      this.keyboard.input = ''
      this.search = ''
    }
  }
}
</script>

<style lang="sass">
  .floor-plan-container
    position: relative
    width: 100%
    flex: 1
    display: flex
    flex-direction: column
  .background-table.v-data-table
    background-color: initial!important
  .room
    opacity: 0
    &:hover, &.active
      fill: var(--room-active-color)
      opacity: 0.6
  .v-data-table--no-row-select .v-data-footer__select
    opacity: 0
  .floor-plan-keyboard
    &.theme--dark.v-sheet
      background-color: #000000b8s
  .floor-plan--vertical
    .floor-plan-details
      position: relative
    .floor-plan-keyboard
      position: absolute
      bottom: 100%
      left: 0
      right: 0
  .floor-plan--horizontal
    .floor-plan-keyboard
      position: absolute
      top: 0
      left: 0
      right: 40%
</style>
